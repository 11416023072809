<script setup lang="ts">
import { signOut } from '#auth';

const emit = defineEmits(['click']);

const { session } = useAuth();

const dropdown = ref<HTMLElement | null>(null);
const role = session.value?.user?.role ?? '';

const onClick = (event: Event) => {
    dropdown.value?.removeAttribute('open');
    emit('click', event);
};
</script>

<template>
    <li v-if="role !== ''" class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink
            @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300"
            :to="`/admin/catalog/me`"
        >Můj profil v katalogu
        </NuxtLink>
    </li>
    <template v-if="['admin', 'editor'].includes(role)">
        <li class="hover:-translate-y-0.5 transition-transform">
            <details ref="dropdown">
                <summary>Menu</summary>
                <ul class="menu dropdown-content bg-base-100 rounded-t-none p-2">
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/vocabulary`"
                        >Slovník
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/blog`"
                        >Blog
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100" active-class="text-base-content bg-base-300"
                            :to="`/admin/faq`"
                        >Poradenství
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/pages`"
                        >Stránky
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/texts`"
                        >Texty
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/tags`"
                        >Štítky
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/users`"
                        >Uživatelé
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/references`"
                        >Reference
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/catalog-references`"
                        >Katalogové reference
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/zdarma`"
                        >Zdarma
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/redirects`"
                        >Přesměrování
                        </NuxtLink>
                    </li>
                    <li class="hover:text-base-content">
                        <NuxtLink
                            @click="onClick" class="hover:bg-base-100"
                            active-class="text-base-content bg-base-300" :to="`/admin/catalog/change-log`"
                        >Změny v katalogu
                        </NuxtLink>
                    </li>
                </ul>
            </details>
        </li>
    </template>
    <li class="hover:-translate-y-0.5 transition-transform">
        <NuxtLink
            @click="onClick" class="hover:bg-base-100"
            active-class="text-base-content bg-base-300" :to="`/`"
        >Zpátky na web
        </NuxtLink>
    </li>

    <li v-if="role !== ''" class="hover:-translate-y-0.5 transition-transform">
        <button @click="signOut()" class="hover:bg-base-100">Odhlásit se</button>
    </li>
</template>

<style scoped lang="postcss">

</style>
